import Form from 'react-bootstrap/Form';
import React from 'react';
import FormGroupRowRO from './FormGroupRO';
import FormGroupRowRW from './FormGroupRW';
import { Accordion, AccordionButton, Card, Table } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { CardBody, CardHeader } from 'reactstrap';

const UPDATED = "5 Nov 2022"
const BASE_INTEREST_RATE_PERCENT = 0.05
const FIELD_TOTAL_ELIGIBLE_TXNS = "eligible_txns";
const FIELD_DIVIDEND_FINDEX_SPENDING = "eligible_div_findex_txns"
class DBS extends React.Component<{
    accBal: number,
    salary: number,
    cardspend: number,
    investment: number,
    insurance: number,
    loan: number,
}, {
    eligible_div_findex_txns: number
}> {
    constructor(props) {
        super(props);
        this.state = {
            eligible_div_findex_txns: 0
        }
    }
    get_txn_bracket(total_eligible_txns: number) {
        if (total_eligible_txns < 2000) {
            return 0
        } else if (total_eligible_txns >= 2000 && total_eligible_txns < 2500) {
            return 1
        } else if (total_eligible_txns >= 2500 && total_eligible_txns < 5000) {
            return 2
        } else if (total_eligible_txns >= 5000 && total_eligible_txns < 15000) {
            return 3
        } else if (total_eligible_txns >= 15000 && total_eligible_txns < 30000) {
            return 4
        } else {
            return 5
        }
    }
    get_tierOneInterest_rate_in_percent() {
        // tierOneInterestRate 
        if (!this.is_eligible_for_salary_div_findex_bonus()) {
            return BASE_INTEREST_RATE_PERCENT
        }
        var num_txns_fulfilled = this.get_num_txns_fulfilled()
        if (num_txns_fulfilled == 0) {
            return BASE_INTEREST_RATE_PERCENT
        }
        var total_eligible_txns = this.get_eligible_spending()
        var bracket = this.get_txn_bracket(total_eligible_txns)
        // console.log(bracket, total_eligible_txns)
        var category = Math.max(Math.min(3, num_txns_fulfilled) - 1, 0)
        var rates_in_percent = [[0.05, 0.9, 1.5, 1.8, 1.9, 2.2], [0.05, 1.7, 1.8, 2.1, 2.2, 3], [0.05, 2, 2.2, 2.4, 2.5, 4.1]]
        return rates_in_percent[category][bracket]
    }
    get_num_txns_fulfilled() {
        var count = 0;
        if (this.is_eligible_for_card_bonus()) {
            count++;
        }
        if (this.is_eligible_for_loan_bonus()) {
            count++;
        }
        if (this.is_eligible_for_insurance_bonus()) {
            count++;
        }
        if (this.is_eligible_for_investment_bonus()) {
            count++;
        }
        return count;
    }
    is_eligible_for_salary_div_findex_bonus() {
        return this.props.salary > 0 || this.state.eligible_div_findex_txns > 0
    }
    is_eligible_for_card_bonus() {
        return this.props.cardspend > 0
    }
    is_eligible_for_loan_bonus() {
        return this.props.loan > 0
    }
    is_eligible_for_insurance_bonus() {
        return this.props.insurance > 0
    }
    is_eligible_for_investment_bonus() {
        return this.props.investment > 0
    }
    calculateBaseInterest_daily() {
        
        var dailyInterest = this.props.accBal / 365 * BASE_INTEREST_RATE_PERCENT / 100
        var dailyInterest_rounded = Math.round(dailyInterest * 100) / 100
        // console.log("baseInterest", dailyInterest_rounded * 30) 
        return dailyInterest_rounded 
    }
    calc_daily_interest_from_tierone() {
        var t1interestrate = this.get_tierOneInterest_rate_in_percent() / 100
        var num_txns_fulfilled = this.get_num_txns_fulfilled()
        var interest_earned = 0;
        switch (num_txns_fulfilled) {
            case 0:
                break;
            case 1:
                interest_earned += Math.round(Math.min(this.props.accBal, 50000) * (t1interestrate - BASE_INTEREST_RATE_PERCENT/100) / 365 * 10000) / 10000;
                break;
            default:
                interest_earned += Math.round(Math.min(this.props.accBal, 100000) * (t1interestrate - BASE_INTEREST_RATE_PERCENT/100) / 365 * 10000) / 10000;
                break;

        }
        // console.log('t1int', interest_earned)
        return interest_earned;
    }
    calculate_preferentialInterest_daily() {
        var pi = Math.round(this.calc_daily_interest_from_tierone() * 100) / 100 
        // console.log("pref interest", pi)
        return pi
    }
    calculate_total_interest_daily() {
        return this.calculate_preferentialInterest_daily() + this.calculateBaseInterest_daily()
    }
    calculate_total_interest_yearly() {
        return this.calculate_total_interest_daily() * 365
    }

    get_eligible_spending() {
        var total = this.state.eligible_div_findex_txns + this.props.loan + this.props.salary + this.props.cardspend + this.props.insurance + this.props.investment;
        return total
    }
    handleChange(fieldname, fieldvalue) {
        switch (fieldname) {
            case FIELD_DIVIDEND_FINDEX_SPENDING:
                this.setState({ eligible_div_findex_txns: fieldvalue })
                break;
        }
    }
    render() {
        // console.log(JSON.stringify(this.state), JSON.stringify(this.props))
        return (
            <Card className='shadow border-left-info' style={{ "minWidth": "250px" }}>
                <CardHeader>
                    <h4>DBS - Multiplier</h4>
                    Updated: {UPDATED}<br />
                    Reference: <a href="https://www.dbs.com.sg/personal/deposits/bank-earn/multiplier">Multiplier Account</a>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Accordion flush={true} defaultActiveKey={['moresettings']} alwaysOpen>
                            <AccordionItem eventKey='eligibilities'>
                                <AccordionButton>
                                    Category Eligibility Check
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRO label="Eligible for Salary/Div/FinDex Bonus" value={this.is_eligible_for_salary_div_findex_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Credit Card Bonus" value={this.is_eligible_for_card_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Home Loan Bonus" value={this.is_eligible_for_loan_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Insurance Bonus" value={this.is_eligible_for_insurance_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Investment Bonus" value={this.is_eligible_for_investment_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible Categories" value={this.get_num_txns_fulfilled()} />
                                    <FormGroupRowRO prepend="S$" value={this.get_eligible_spending().toFixed(2)} controlId={FIELD_TOTAL_ELIGIBLE_TXNS} label="Total Eligible Transactions" />
                                </AccordionBody>
                            </AccordionItem>

                            <AccordionItem eventKey='moresettings'>
                                <AccordionButton>
                                    Additional Settings
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRW prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_DIVIDEND_FINDEX_SPENDING} label="Eligible Dividends/FinDEX Transactions" placeholder="0" />
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>

                        <FormGroupRowRO postpend="%" label="Effective Interest rate per annum" value={(this.calculate_total_interest_yearly() / this.props.accBal * 100).toFixed(3)} />
                        {/* <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (30 Days)" value={(this.calculate_total_interest_daily() * 30).toFixed(2)} /> */}
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (31 Days)" value={(this.calculate_total_interest_daily() * 31).toFixed(2)} />
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Yearly (365 Days)" value={this.calculate_total_interest_yearly().toFixed(2)} />
                    </Form>
                </CardBody>
            </Card>
        );
    }
}
export default DBS;