import Form from 'react-bootstrap/Form';
import React from 'react';
import FormGroupRowRW from './FormGroupRW';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Table } from 'react-bootstrap';

const FIELD_ACCOUNT_BALANCE = "accountbalance";
const FIELD_SALARY_CREDITING = "salarycrediting";
const FIELD_CARD_SPENDING = "cardspending";
const FIELD_INVESTMENTS = "investments";
const FIELD_LOAN_INSTALLMENTS = "loaninstallments";
const FIELD_INSURANCE_PAYMENTS = "insurancepayments";

interface CashFlowDetailsProps {
    onChange?: any;
    accBal?: number,
    salary?: number,
    cardspend?: number,
    investment?: number,
    insurance?: number,
    loan?: number,
}
class CashFlowDetails extends React.Component<CashFlowDetailsProps, {
    accBal: number,
    salary: number,
    cardspend: number,
    investment: number,
    insurance: number,
    loan: number,
}> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            accBal: this.props.accBal,
            salary: this.props.salary,
            cardspend: this.props.cardspend,
            investment: this.props.investment,
            insurance: this.props.insurance,
            loan: this.props.loan,
        };
    }
    calc_eligible_spending() {
        var total = this.state.loan + this.state.salary + this.state.cardspend + this.state.insurance + this.state.investment;
        return total
    }
    handleChange(fieldName, fieldValue) {
        var copy = {}
        Object.assign(copy, this.state);
        switch (fieldName) {
            case FIELD_ACCOUNT_BALANCE:
                this.setState({ accBal: fieldValue })
                copy['accBal'] = fieldValue;
                break;
            case FIELD_CARD_SPENDING:
                this.setState({ cardspend: fieldValue })
                copy['cardspend'] = fieldValue;
                break;
            case FIELD_INSURANCE_PAYMENTS:
                this.setState({ insurance: fieldValue })
                copy['insurance'] = fieldValue;
                break;
            case FIELD_INVESTMENTS:
                this.setState({ investment: fieldValue })
                copy['investment'] = fieldValue;
                break;
            case FIELD_LOAN_INSTALLMENTS:
                this.setState({ loan: fieldValue })
                copy['loan'] = fieldValue;
                break;
            case FIELD_SALARY_CREDITING:
                this.setState({ salary: fieldValue })
                copy['salary'] = fieldValue;
                break;
        }
        if (this.props.onChange) {
            this.props.onChange(copy)
        }
    }
    render() {
        return (

            <Card className='shadow border-left-primary' style={{maxWidth: "650px"}}>
                <CardHeader>
                    <h4>General</h4>
                </CardHeader>
                <CardBody >
                    <Form action=''>
                        <FormGroupRowRW value={this.state.accBal} prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_ACCOUNT_BALANCE} label="Account Balance" placeholder="0" />
                        <FormGroupRowRW value={this.state.salary} prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_SALARY_CREDITING} label="Salary Crediting" placeholder="0"></FormGroupRowRW>
                        <FormGroupRowRW value={this.state.cardspend} prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_CARD_SPENDING} label="Eligible Card Spending" placeholder="0"></FormGroupRowRW>
                        <FormGroupRowRW value={this.state.investment} prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_INVESTMENTS} label="Eligible Investment Spending" placeholder="0"></FormGroupRowRW>
                        <FormGroupRowRW value={this.state.loan} prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_LOAN_INSTALLMENTS} label="Eligible Loan Installment Payments" placeholder="0"></FormGroupRowRW>
                        <FormGroupRowRW value={this.state.insurance} prepend="S$" onChange={this.handleChange.bind(this)} controlId={FIELD_INSURANCE_PAYMENTS} label="Eligible Insurance Premium Payments" placeholder="0"></FormGroupRowRW>
                    </Form>
                </CardBody>
            </Card>
        );
    }
    componentDidMount(): void {

    }
    componentWillUnmount(): void {

    }
}


export default CashFlowDetails;