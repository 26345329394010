
function calculateD(acct_balance: number) {
  var balance = acct_balance;

  var remainder = balance;
  var interest_earned = 0.0;

  var tiers = [30000, 30000, 15000, 25000, 25000, 25000];
  var rates = [3.0, 3.0, 3.0, 4.5, 4.5, 6.0];

  for (var i = 0; i < tiers.length; ++i) {
    var tier = tiers[i]
    var rate = rates[i]
    if (remainder >= tier) {
      remainder -= tier;
      interest_earned += (tier * rate) / 100 || 0;
    } else {
      interest_earned += (remainder * rate) / 100 || 0;
      return interest_earned;
    }
  }
  if (remainder > 0) {
    interest_earned += (remainder * 0.05) / 100 || 0;
  }
  return interest_earned
}


function calculateC(acct_balance: number) {
  var baseInterest = (acct_balance * 0.05) / 100 || 0;
  return baseInterest;
}

function calculateB(acct_balance: number) {

  var balance = acct_balance;

  var remainder = balance;
  var interest_earned = 0.0;

  var tiers = [30000, 30000, 15000, 25000, 25000, 25000];
  var rates = [2.0, 2.0, 2.0, 3.0, 3.0, 0.05];

  for (var i = 0; i < tiers.length; ++i) {
    var tier = tiers[i]
    var rate = rates[i]
    if (remainder >= tier) {
      remainder -= tier;
      interest_earned += (tier * rate) / 100 || 0;
    } else {
      interest_earned += (remainder * rate) / 100 || 0;
      return interest_earned;
    }
  }
  if (remainder > 0) {
    interest_earned += (remainder * 0.05) / 100 || 0;
  }
  return interest_earned
}

function calculateA(acct_balance: number) {

  var balance = acct_balance;

  var remainder = balance;
  var interest_earned = 0.0;

  var tiers = [30000, 30000, 15000, 25000, 25000, 25000];
  var rates = [0.65, 0.65, 0.65, 0.05, 0.05, 0.05];

  for (var i = 0; i < tiers.length; ++i) {
    var tier = tiers[i]
    var rate = rates[i]
    if (remainder >= tier) {
      remainder -= tier;
      interest_earned += (tier * rate) / 100 || 0;
    } else {
      interest_earned += (remainder * rate) / 100 || 0;
      return interest_earned;
    }
  }
  if (remainder > 0) {
    interest_earned += (remainder * 0.05) / 100 || 0;
  }
  return interest_earned
}

export function calculate_interest_earned_annual(monthlyCardSpend: number, monthlySalaryCredit: number, monthlyGiroTxns: number, acct_balance: number) {
  var interest_earned_total;
  if (monthlyCardSpend < 500) {
    interest_earned_total = calculateC(acct_balance)
  } else if (monthlySalaryCredit >= 1600) {
    interest_earned_total = calculateD(acct_balance)
  } else if (monthlyGiroTxns >= 3) {
    interest_earned_total = calculateB(acct_balance)
  } else {
    interest_earned_total = calculateA(acct_balance)
  }
  return interest_earned_total;
}

export function get_card_rebate_annual(monthlyCardSpend: number) {
  if (monthlyCardSpend >= 2000) {
    return 800;
  } else if (monthlyCardSpend >= 1000) {
    return 400;
  } else if (monthlyCardSpend >= 500) {
    return 200;
  } else if (monthlyCardSpend < 500) {
    return 0;
  }
}
