import React from "react";
import { Container, Row, Col, FormCheck } from "react-bootstrap";
import CashFlowDetails from "./components/CashFlowDetails";
import DBS from "./components/DBS";
import FeedbackForm from "./components/FeedbackForm";
import MyNavBar from "./components/Nav";
import OCBC from './components/OCBC';
import UOB from "./components/UOB";
import SCB from "./components/SCB";
import SCB1stMay from "./components/SCB-1st-may-2024";
import UOB1stMay from "./components/UOB-1st-may-2024"
import { Card, CardBody, CardHeader, Form } from 'reactstrap';

// import './App.css';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accBal: 0,
      salary: 0,
      cardspend: 0,
      investment: 0,
      insurance: 0,
      loan: 0,
      showSCBnew: true,
      showSCB: true,
      showUOB: true,
      showUOBnew: true,
      showOCBC: true,
      showDBS: true,
    };
  }
  handleChange(state) {
    Object.assign(this.state, state);
    // console.log('test', this.state)
    this.setState({ 'test': 0 })
  }
  visibilityChangedSCBNew(value) {
    this.setState({ 'showSCBnew': value.target.checked })
  }
  visibilityChangedSCB(value) {
    this.setState({ 'showSCB': value.target.checked })
  }
  visibilityChangedUOB(value) {
    this.setState({ 'showUOB': value.target.checked })
  }
  visibilityChangedUOBNew(value) {
    this.setState({ 'showUOBnew': value.target.checked })
  }
  visibilityChangedOCBC(value) {
    this.setState({ 'showOCBC': value.target.checked })
  }
  visibilityChangedDBS(value) {
    this.setState({ 'showDBS': value.target.checked })
  }
  render() {
    return (
      <div>
        <div className="App">
          <Container fluid className="m-3">
            <Row >
              <Col className='m-1'>
                <CashFlowDetails investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} onChange={this.handleChange.bind(this)} />
              </Col>
              <Col className='m-1'>


                <Card className='shadow border-left-primary' style={{ maxWidth: "650px" }}>
                  <CardHeader>
                    <h4>Toggle</h4>
                  </CardHeader>
                  <CardBody >
                    <Form>
                      <FormCheck label="SCB" checked={this.state['showSCB']} onChange={this.visibilityChangedSCB.bind(this)}></FormCheck>
                      <FormCheck label="SCB (1st May)" checked={this.state['showSCBnew']} onChange={this.visibilityChangedSCBNew.bind(this)}></FormCheck>
                      <FormCheck label="UOB" checked={this.state['showUOB']} onChange={this.visibilityChangedUOB.bind(this)}></FormCheck>
                      <FormCheck label="UOB (1st May)" checked={this.state['showUOBnew']} onChange={this.visibilityChangedUOBNew.bind(this)}></FormCheck>
                      <FormCheck label="OCBC" checked={this.state['showOCBC']} onChange={this.visibilityChangedOCBC.bind(this)}></FormCheck>
                      <FormCheck label="DBS" checked={this.state['showDBS']} onChange={this.visibilityChangedDBS.bind(this)}></FormCheck>
                    </Form>
                  </CardBody>
                </Card>

              </Col>
            </Row>

            <Row>
              <Col className='m-1' hidden={!this.state['showSCB']}>
                <SCB investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} />
              </Col>
              <Col className='m-1' hidden={!this.state['showSCBnew']}>
                <SCB1stMay investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} />
              </Col>
              <Col className='m-1' hidden={!this.state['showUOB']}>
                <UOB investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} />
              </Col>
              <Col className='m-1' hidden={!this.state['showUOBnew']}>
                <UOB1stMay investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} />
              </Col>
              <Col className='m-1' hidden={!this.state['showOCBC']}>
                <OCBC investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} />
              </Col>
              <Col className='m-1' hidden={!this.state['showDBS']}>
                <DBS investment={this.state['investment']} salary={this.state['salary']} loan={this.state['loan']} insurance={this.state['insurance']} cardspend={this.state['cardspend']} accBal={this.state['accBal']} />
              </Col>
            </Row>
            <Row>
              <Col className='m-1' >
                {/* <FeedbackForm /> */}
                <a href="mailto:ky_99249@hotmail.com">Email Me</a>
              </Col>
            </Row>
          </Container>

        </div>
      </div>

    );
  }
}
// const element = document.getElementById('root');
// const root = createRoot(element!);
export default App;
