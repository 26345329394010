import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';

interface FormGroupRowRWProps {
    controlId: string;
    label: string;
    placeholder: string;
    prepend?: string;
    postpend?: string;
    onChange: any;
    value?: any
}


class FormGroupRowRW extends React.Component<FormGroupRowRWProps, {}> {
    onFieldChange(event) {
        // for a regular input field, read field name and value from the event
        const fieldName = event.target.name;
        var fieldValue = event.target.value;
        if (fieldValue.trim() === "") {
            fieldValue = 0
        } else {
            fieldValue = parseFloat(fieldValue.trim())
        }
        this.props.onChange(fieldName, fieldValue);
    }
    render() {
        const postpend = []
        const prepend = []
        var formcontrol_props = (
            <Form.Control
                value={this.props.value}
                name={this.props.controlId}
                type="number"
                min="0"
                max="99999999"
                placeholder={this.props.placeholder}
                onChange={this.onFieldChange.bind(this)}
            />)

        if (this.props.postpend) {
            postpend.push(<InputGroup.Text id="inputGroupPostpend">{this.props.postpend}</InputGroup.Text>)
            formcontrol_props = (
                <Form.Control
                    name={this.props.controlId}
                    type="number"
                    min="0"
                    max="99999999"
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldChange.bind(this)}
                    aria-describedby="inputGroupPostpend"
                />)

        } else if (this.props.prepend) {
            prepend.push(<InputGroup.Text id="inputGroupPrepend">{this.props.prepend}</InputGroup.Text>)
            formcontrol_props = (
                <Form.Control
                    name={this.props.controlId}
                    type="number"
                    min="0"
                    max="99999999"
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldChange.bind(this)}
                    aria-describedby="inputGroupPrepend"
                />)

        }

        return (
            <Form.Group controlId={this.props.controlId}>
                <Row className='mb-2' >
                    <Col style={{ "alignItems": "stretch" }}>
                        <Form.Label className='col-form-label'>{this.props.label}</Form.Label>
                    </Col>
                    <Col className='justify-content: flex-end'>
                        <InputGroup hasValidation style={{ "flexWrap": "nowrap", "minWidth": "130px" }}>
                            {prepend}
                            {formcontrol_props}
                            {postpend}
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid amt.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        );
    }
}
export default FormGroupRowRW;