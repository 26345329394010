import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
function FormGroupRowRO(props) {
    const postpend = []
    const prepend = []
    var formcontrol_props = (
        <Form.Control
            value={props.value}
            name={props.controlId}
            placeholder={props.placeholder}
            readOnly={true}
        />)

    if (props.postpend) {
        postpend.push(<InputGroup.Text id="inputGroupPostpend">{props.postpend}</InputGroup.Text>)
        formcontrol_props = (
            <Form.Control
                value={props.value}
                name={props.controlId}
                placeholder={props.placeholder}
                readOnly={true}
                aria-describedby="inputGroupPostpend"
            />)

    } else if (props.prepend) {
        prepend.push(<InputGroup.Text id="inputGroupPrepend">{props.prepend}</InputGroup.Text>)
        formcontrol_props = (
            <Form.Control
                value={props.value}
                name={props.controlId}
                placeholder={props.placeholder}
                readOnly={true}
                aria-describedby="inputGroupPrepend"
            />)

    }

    const elements = (
        <Form.Group>
            <Row className='mb-2'>
                <Col style={{ "alignItems": "stretch" }}>
                    <Form.Label className='col-form-label' style={{ "minWidth": "130px" }}>{props.label}</Form.Label>
                </Col>
                <Col className='justify-content: flex-end'>
                    <InputGroup style={{ "flexWrap": "nowrap", "minWidth": "130px" }}>
                        {prepend}
                        {formcontrol_props}
                        {postpend}
                    </InputGroup>
                </Col>
            </Row>
        </Form.Group>);
    return elements

    // return (<Form.Group >
    //     <Row >
    //         <Col >
    //             <Form.Label className='col-form-label'>{props.lael}</Form.Label>
    //         </Col>
    //         <Col >
    //             <InputGroup>
    //                 <Form.Control
    //                     value={props.value}
    //                     type="text"
    //                     readOnly={true}
    //                 />
    //             </InputGroup>
    //         </Col>
    //     </Row>
    // </Form.Group>);
}
export default FormGroupRowRO;
