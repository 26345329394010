import Form from 'react-bootstrap/Form';
import React from 'react';
import FormGroupRowRO from './FormGroupRO';
import FormGroupRowRW from './FormGroupRW';
import { Accordion, AccordionButton, Card, Table } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { CardBody, CardHeader } from 'reactstrap';

const UPDATED = "4 Nov 2022"
class OCBC extends React.Component<{
    accBal: number,
    salary: number,
    cardspend: number,
    investment: number,
    insurance: number,
    loan: number,
}, {
    monthlysavingsgrowth: number
}> {
    constructor(props) {
        super(props);
        this.state = {
            monthlysavingsgrowth: 0
        }
    }
    is_eligible_for_salary_bonus() {
        return this.props.salary >= 1800
    }
    is_eligible_for_save_bonus() {
        return this.state.monthlysavingsgrowth >= 500
    }
    is_eligible_for_spend_bonus() {
        return this.props.cardspend >= 500
    }
    is_eligible_for_grow_bonus() {
        return this.props.accBal >= 200000
    }
    is_eligible_for_insure_bonus() {
        return this.props.insurance > 0
    }
    is_eligible_for_invest_bonus() {
        return this.props.investment > 0
    }
    calc_interest_from_salary_bonus() {
        var first_75000 = Math.min(this.props.accBal, 75000) * 0.02;
        var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.04;
        // console.log('salary bonus: ', first_75000 + next_25000)
        return first_75000 + next_25000
    }
    calc_interest_from_save_bonus() {
        var first_75000 = Math.min(this.props.accBal, 75000) * 0.012;
        var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.024;
        // console.log('save bonus: ', first_75000 + next_25000)
        return first_75000 + next_25000
    }
    calc_interest_from_spend_bonus() {
        var first_75000 = Math.min(this.props.accBal, 75000) * 0.006;
        var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.006;
        return first_75000 + next_25000
    }
    calc_interest_from_insure_bonus() {
        var first_75000 = Math.min(this.props.accBal, 75000) * 0.012;
        var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.024;
        return first_75000 + next_25000
    }
    calc_interest_from_invest_bonus() {
        var first_75000 = Math.min(this.props.accBal, 75000) * 0.012;
        var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.024;
        return first_75000 + next_25000
    }
    calc_interest_from_grow_bonus() {
        var first_75000 = Math.min(this.props.accBal, 75000) * 0.024;
        var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.024;
        // console.log('grow bonus: ', first_75000 + next_25000)
        return first_75000 + next_25000
    }
    handleChange(fieldname, fieldvalue) {
        this.setState({ monthlysavingsgrowth: fieldvalue })
    }
    calculate_annual_interest() {
        var total = 0;
        if (this.is_eligible_for_salary_bonus()) {
            total += this.calc_interest_from_salary_bonus();
        }
        if (this.is_eligible_for_save_bonus()) {
            total += this.calc_interest_from_save_bonus();
        }
        if (this.is_eligible_for_spend_bonus()) {
            total += this.calc_interest_from_spend_bonus();
        }
        if (this.is_eligible_for_insure_bonus()) {
            total += this.calc_interest_from_insure_bonus();
        }
        if (this.is_eligible_for_invest_bonus()) {
            total += this.calc_interest_from_invest_bonus();
        }
        if (this.is_eligible_for_grow_bonus()) {
            total += this.calc_interest_from_grow_bonus();
        }
        total += this.props.accBal * 0.0005
        return total
    }
    calculate_annual_interest_in_percent() {
        return (this.calculate_annual_interest() / this.props.accBal * 100)
    }
    render() {
        // console.log(JSON.stringify(this.state), JSON.stringify(this.props))
        return (
            <Card className='shadow border-left-info' style={{ "minWidth": "250px" }}>
                <CardHeader>
                    <h4>OCBC - 360</h4>
                    Updated: {UPDATED}<br />
                    Reference: <a href="https://www.ocbc.com/personal-banking/deposits/360-savings-account">OCBC 360</a>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Accordion flush={true} defaultActiveKey='moresettings' alwaysOpen>
                            <AccordionItem  eventKey='eligibilities'>
                                <AccordionButton>
                                    Category Eligibility Check
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRO label="Eligible for Salary Bonus" value={this.is_eligible_for_salary_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Save Bonus" value={this.is_eligible_for_save_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Spend Bonus" value={this.is_eligible_for_spend_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Insure Bonus" value={this.is_eligible_for_insure_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Invest Bonus" value={this.is_eligible_for_invest_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Grow Bonus" value={this.is_eligible_for_grow_bonus() ? "Yes" : "No"} />
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem eventKey='moresettings'>
                                <AccordionButton>
                                    Additional Settings
                                </AccordionButton>
                                <AccordionBody >
                                    <FormGroupRowRW prepend="S$" onChange={this.handleChange.bind(this)} controlId="monthlysavingsgrowth" label="Monthly Increase in 'Average Daily Balance'" placeholder="0" />
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        <FormGroupRowRO postpend="%" label="Effective Interest rate per annum" value={this.calculate_annual_interest_in_percent().toFixed(3)} />
                        {/* <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (30 Days)" value={(this.calculate_annual_interest() / 365 * 30).toFixed(2)} /> */}
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (31 Days)" value={(this.calculate_annual_interest() / 365 * 31).toFixed(2)} />
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Yearly (365 Days)" value={this.calculate_annual_interest().toFixed(2)} />
                    </Form>
                </CardBody>
            </Card>
        );
    }
}
export default OCBC;