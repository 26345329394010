import Form from 'react-bootstrap/Form';
import React from 'react';
import FormGroupRowRO from './FormGroupRO';
import FormGroupRowRW from './FormGroupRW';
import { get_card_rebate_annual, calculate_interest_earned_annual } from './uob-calc';
import { Accordion, AccordionButton, Table } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { Card, CardBody, CardHeader } from 'reactstrap';

const UPDATED = "25 Dec 2022"
class UOB extends React.Component<{
    accBal: number,
    salary: number,
    cardspend: number,
    investment: number,
    insurance: number,
    loan: number,
}, {
    monthly_giro_txns: number
}> {
    constructor(props) {
        super(props)
        this.state = {
            monthly_giro_txns: 0
        }
    }
    is_eligible_for_salary_bonus() {
        return this.props.salary >= 1600 || this.state.monthly_giro_txns >= 3
    }
    is_eligible_for_spend_bonus() {
        return this.props.cardspend >= 500
    }
    calc_interest_from_salary_bonus() {
        var dividend = 15000
        var dividend2 = 25000
        var divideResult = Math.floor(this.props.accBal / dividend)
        var getModulus = this.props.accBal % dividend
        var calcResult = divideResult * dividend + getModulus

        var setArray = [getModulus]
        for (var i = 0; i < divideResult; i++) {
            if (divideResult <= 5) {
                setArray.push(dividend)
            } else {
                var restamt = this.props.accBal - 75000
                if (restamt <= dividend2) {
                    setArray = [dividend, dividend, dividend, dividend, dividend, restamt, 0]
                } else {
                    var lastamt = restamt - dividend2
                    setArray = [dividend, dividend, dividend, dividend, dividend, dividend2, lastamt]
                }
            }
        }
        if (divideResult <= 5) {
            setArray.push(setArray.shift())
        }

        //set of first six array
        var itemB0 = (setArray[0] * 3.85) / 100 || 0
        var itemB1 = (setArray[1] * 3.85) / 100 || 0
        var itemB2 = (setArray[2] * 3.9) / 100 || 0
        var itemB3 = (setArray[3] * 3.9) / 100 || 0
        var itemB4 = (setArray[4] * 4.85) / 100 || 0
        var itemB5 = (setArray[5] * 7.8) / 100 || 0
        var itemB6 = (setArray[6] * 0.05) / 100 || 0

        //total of first six array
        var totalInterestSceneB = itemB0 + itemB1 + itemB2 + itemB3 + itemB4 + itemB5 + itemB6
        return totalInterestSceneB
    }
    // calc_interest_from_spend_bonus() {

    //     var first_75000 = Math.min(this.props.accBal, 75000) * 0.006;
    //     var next_25000 = Math.min(25000, Math.max(this.props.accBal - 75000, 0)) * 0.006;
    //     return first_75000 + next_25000
    // }
    
    handleChange(fieldname, fieldvalue) {
        switch (fieldname) {
            case "monthlygirotxns":
                this.setState({ monthly_giro_txns: fieldvalue })
                break;
        }
    }
    calculate_annual_interest() {
        var interest_earned_annually = calculate_interest_earned_annual(this.props.cardspend, this.props.salary, this.state.monthly_giro_txns, this.props.accBal)
        return interest_earned_annually
    }
    calculate_annual_interest_in_percent() {
        return this.calculate_annual_interest() / this.props.accBal * 100
    }

    render() {
        // console.log(JSON.stringify(this.state), JSON.stringify(this.props))
        return (
            <Card className='shadow border-left-info' style={{ "minWidth": "250px" }}>
                <CardHeader>
                    <h4>UOB - One</h4>
                    Updated: {UPDATED}<br />
                    Reference: <a href="https://www.uob.com.sg/personal/save/everyday-accounts/one-account.page">One-Account</a>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Accordion flush={true} defaultActiveKey='moresettings' alwaysOpen>
                            <AccordionItem eventKey='eligibilities'>
                                <AccordionButton>
                                    Category Eligibility Check
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRO label="Eligible for Salary Bonus" value={this.is_eligible_for_salary_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Spend Bonus" value={this.is_eligible_for_spend_bonus() ? "Yes" : "No"} />
                                </AccordionBody>
                            </AccordionItem>

                            <AccordionItem eventKey='moresettings'>
                                <AccordionButton>
                                    Additional Settings
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRW onChange={this.handleChange.bind(this)} controlId="monthlygirotxns" postpend='transactions' label="GIRO Transactions" placeholder="0" />
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        <FormGroupRowRO postpend="%" label="Effective Interest rate per annum" value={this.calculate_annual_interest_in_percent().toFixed(3)} />
                        {/* <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (30 Days)" value={(this.calculate_annual_interest() / 365 * 30).toFixed(2)} /> */}
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (31 Days)" value={(this.calculate_annual_interest() / 365 * 31).toFixed(2)} />
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Yearly (365 Days)" value={this.calculate_annual_interest().toFixed(2)} />
                    </Form>
                </CardBody>
            </Card>
        );
    }
}
export default UOB;