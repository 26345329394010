
function calculateD(acct_balance: number) {
  var dividend = 15000;
  var dividend2 = 25000;
  var balance = acct_balance;


  // console.log('calculateB', cardSpend, sallaryCredit, giro, balance);
  var divideResult = Math.floor(balance / dividend);
  var getModulus = balance % dividend;

  var setArray = [getModulus];
  for (var i = 0; i < divideResult; i++) {
    if (divideResult <= 5) {
      setArray.push(dividend);
    } else {
      var restamt = balance - 75000;
      if (restamt <= dividend2) {
        setArray = [dividend, dividend, dividend, dividend, dividend, restamt, 0];
      } else {
        var lastamt = restamt - dividend2;
        setArray = [dividend, dividend, dividend, dividend, dividend, dividend2, lastamt];
      }
    }
  }
  if (divideResult <= 5) {
    setArray.push(setArray.shift());
  }

  //set of first six array
  var itemB0 = (setArray[0] * 3.85) / 100 || 0;
  var itemB1 = (setArray[1] * 3.85) / 100 || 0;
  var itemB2 = (setArray[2] * 3.9) / 100 || 0;
  var itemB3 = (setArray[3] * 3.9) / 100 || 0;
  var itemB4 = (setArray[4] * 4.85) / 100 || 0;
  var itemB5 = (setArray[5] * 7.8) / 100 || 0;
  var itemB6 = (setArray[6] * 0.05) / 100 || 0;

  //total of first six array
  var totalInterestSceneB = itemB0 + itemB1 + itemB2 + itemB3 + itemB4 + itemB5 + itemB6;

  //slice from 6th array
  var sliceArray = setArray.slice(7);

  //multiply each array
  var multiply = sliceArray.map(function (x) {
    return (x * 0.05) / 100 || 0;
  });

  //remove last array from list
  //multiply.splice(-1,1)

  //sum of all list of array
  var sum = multiply.reduce(function (a, b) {
    return a + b;
  }, 0);

  var totalArray = sum + totalInterestSceneB;

  //prevents rounding off of decimal
  var toFixed = function (val, decimals) {
    var arr = ("" + val).split(".");
    if (arr.length === 1) return val;
    var int = arr[0],
      dec = arr[1],
      max = dec.length - 1;
    return decimals === 0 ? int : [int, ".", dec.substr(0, decimals > max ? max : decimals)].join("");
  };
  var totalArrayNoroundOff = totalArray;
  return totalArrayNoroundOff
}


function calculateC(acct_balance: number) {
  var baseInterest = (acct_balance * 0.05) / 100 || 0;
  return baseInterest;
}

function calculateB(acct_balance: number) {

  var dividend = 15000;
  var dividend2 = 25000;
  var balance = acct_balance;

  // console.log('calculateB', cardSpend, sallaryCredit, giro, balance);
  var divideResult = Math.floor(balance / dividend);
  var getModulus = balance % dividend;
  var calcResult = divideResult * dividend + getModulus

  var setArray = [getModulus];
  for (var i = 0; i < divideResult; i++) {
    if (divideResult <= 5) {
      setArray.push(dividend);
    } else {
      var restamt = balance - 75000;
      if (restamt <= dividend2) {
        setArray = [dividend, dividend, dividend, dividend, dividend, restamt, 0];
      } else {
        var lastamt = restamt - dividend2;
        setArray = [dividend, dividend, dividend, dividend, dividend, dividend2, lastamt];
      }
    }
  }
  if (divideResult <= 5) {
    setArray.push(setArray.shift());
  }

  //set of first six array
  var itemB0 = (setArray[0] * 2.5) / 100 || 0;
  var itemB1 = (setArray[1] * 2.5) / 100 || 0;
  var itemB2 = (setArray[2] * 3) / 100 || 0;
  var itemB3 = (setArray[3] * 3) / 100 || 0;
  var itemB4 = (setArray[4] * 4) / 100 || 0;
  var itemB5 = (setArray[5] * 0.05) / 100 || 0;
  var itemB6 = (setArray[6] * 0.05) / 100 || 0;

  //total of first six array
  var totalInterestSceneB = itemB0 + itemB1 + itemB2 + itemB3 + itemB4 + itemB5 + itemB6;

  //slice from 6th array
  var sliceArray = setArray.slice(7);

  //multiply each array
  var multiply = sliceArray.map(function (x) {
    return (x * 0.05) / 100 || 0;
  });

  //remove last array from list
  //multiply.splice(-1,1)

  //sum of all list of array
  var sum = multiply.reduce(function (a, b) {
    return a + b;
  }, 0);

  var totalArray = sum + totalInterestSceneB;

  var totalArrayNoroundOff = totalArray;
  return totalArrayNoroundOff
}

function calculateA(acct_balance: number) {

  // console.log('calculateA');
  var dividend = 15000;
  var dividend2 = 25000;
  // var sallaryCredit = $('#sallaryCredit').val();
  // var giro = $('#giro').val();

  var balance = acct_balance
  var divideResult = Math.floor(balance / dividend);
  // console.log(divideResult);
  var getModulus = balance % dividend;
  var calcResult = divideResult * dividend + getModulus;

  var setArray = [getModulus];
  for (var i = 0; i < divideResult; i++) {
    if (divideResult <= 5) {
      setArray.push(dividend);
    } else {
      var restamt = balance - 75000;
      console.log("restamt" + restamt);
      if (restamt <= dividend2) {
        //console.log('restamt' +restamt);
        setArray = [dividend, dividend, dividend, dividend, dividend, restamt, 0];
      } else {
        var lastamt = restamt - dividend2;
        setArray = [dividend, dividend, dividend, dividend, dividend, dividend2, lastamt];
      }
    }
  }
  if (divideResult <= 5) {
    setArray.push(setArray.shift());
  }

  //set of first six array
  var itemA0 = (setArray[0] * 0.65) / 100 || 0;
  var itemA1 = (setArray[1] * 0.65) / 100 || 0;
  var itemA2 = (setArray[2] * 0.65) / 100 || 0;
  var itemA3 = (setArray[3] * 0.65) / 100 || 0;
  var itemA4 = (setArray[4] * 0.65) / 100 || 0;
  var itemA5 = (setArray[5] * 0.05) / 100 || 0;
  var itemA6 = (setArray[6] * 0.05) / 100 || 0;
  //total of first six array
  var totalInterestSceneA = itemA0 + itemA1 + itemA2 + itemA3 + itemA4 + itemA5 + itemA6;

  //slice from 6th array
  var sliceArray = setArray.slice(7);

  //multiply each array
  var multiply = sliceArray.map(function (x) {
    return (x * 0.05) / 100 || 0;
  });

  //remove last array from list
  //multiply.splice(-1,1)

  //sum of all list of array
  var sum = multiply.reduce(function (a, b) {
    return a + b;
  }, 0);

  var totalArray = sum + totalInterestSceneA;

  //prevents rounding off of decimal
  var toFixed = function (val, decimals) {
    var arr = ("" + val).split(".");
    if (arr.length === 1) return val;
    var int = arr[0],
      dec = arr[1],
      max = dec.length - 1;
    return decimals === 0 ? int : [int, ".", dec.substr(0, decimals > max ? max : decimals)].join("");
  };
  var totalArrayNoroundOff = totalArray;
  return totalArrayNoroundOff
}

export function calculate_interest_earned_annual(monthlyCardSpend: number, monthlySalaryCredit: number, monthlyGiroTxns: number, acct_balance: number) {
  var interest_earned_total;
  if (monthlyCardSpend < 500) {
      interest_earned_total = calculateC(acct_balance)
  } else if (monthlySalaryCredit >= 1600) {
      interest_earned_total = calculateD(acct_balance)
  } else if (monthlyGiroTxns >= 3) {
      interest_earned_total = calculateB(acct_balance)
  } else {
      interest_earned_total = calculateA(acct_balance)
  }
  return interest_earned_total;
}

export function get_card_rebate_annual(monthlyCardSpend: number) {
  if (monthlyCardSpend >= 2000) {
      return 800;
  } else if (monthlyCardSpend >= 1000) {
      return 400;
  } else if (monthlyCardSpend >= 500) {
      return 200;
  } else if (monthlyCardSpend < 500) {
      return 0;
  }
}
