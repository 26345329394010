import Form from 'react-bootstrap/Form';
import React from 'react';
import FormGroupRowRO from './FormGroupRO';
import FormGroupRowRW from './FormGroupRW';
import { Accordion, AccordionButton, Table } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { Card, CardBody, CardHeader } from 'reactstrap';

const UPDATED = "7 Jan 2023"
class SCB extends React.Component<{
    accBal: number,
    salary: number,
    cardspend: number,
    investment: number,
    insurance: number,
    loan: number,
}, {
    monthly_bill_txns: number
}> {
    constructor(props) {
        super(props)
        this.state = {
            monthly_bill_txns: 0
        }
    }
    is_eligible_for_invest_bonus() {
        return this.props.investment > 0
    }
    is_eligible_for_salary_bonus() {
        return this.props.salary >= 3000
    }
    is_eligible_for_insurance_bonus() {
        return this.props.insurance > 0
    }
    is_eligible_for_spend_bonus() {
        return this.props.cardspend >= 500
    }
    is_eligible_for_bill_payment_bonus(){
        return this.state.monthly_bill_txns >=3;
    }
    getBonusRate_in_percent() {
        var base_interest_rate_in_percent = 0.05;
        var interest_rate_in_percent = base_interest_rate_in_percent;
        
        if (this.is_eligible_for_spend_bonus()){
            if (this.props.cardspend >=500 && this.props.cardspend < 2000){
                interest_rate_in_percent += 1.25
            }
            else if (this.props.cardspend >=2000){
                interest_rate_in_percent += 2.00
            }
        }
        if (this.is_eligible_for_salary_bonus()) {
            interest_rate_in_percent += 2.5
        }

        if (this.is_eligible_for_invest_bonus()){
            interest_rate_in_percent += 1.5
        }
        if (this.is_eligible_for_insurance_bonus()) {
            interest_rate_in_percent += 1.5
        }
        if (this.is_eligible_for_bill_payment_bonus()){
            interest_rate_in_percent += 0.33

        }

// t(".deposit_profit").text(e.decimalPlaces(interest_amt_earned)),
        // t(".deposit_rate").text(e.decimalPlaces(interest_rate_in_percent))
        return interest_rate_in_percent
    }
    
    calculate_annual_interest() {
        var base_interest_rate_in_percent = 0.05;
        var account_balance = this.props.accBal
        var account_balance_bonus_cap = 100000;
        var interest_rate_in_percent = this.getBonusRate_in_percent();
        
        var interest_earned_annually = account_balance_bonus_cap < account_balance ? account_balance_bonus_cap * interest_rate_in_percent / 100 + (account_balance -= account_balance_bonus_cap) * base_interest_rate_in_percent / 100 : account_balance * interest_rate_in_percent / 100
        
        return interest_earned_annually
    }
    calculate_annual_interest_in_percent() {
        return this.calculate_annual_interest() / this.props.accBal * 100
    }

    handleChange(fieldname, fieldvalue) {
        this.setState({ monthly_bill_txns: fieldvalue })
    }
    render() {
        // console.log(JSON.stringify(this.state), JSON.stringify(this.props))
        return (
            <Card className='shadow border-left-info' style={{ "minWidth": "250px" }}>
                <CardHeader>
                    <h4>SCB - Bonus$aver</h4>
                    Updated: {UPDATED}<br />
                    Reference: <a href="https://www.sc.com/sg/save/current-accounts/bonussaver/">Bonus Saver</a>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Accordion flush={true} defaultActiveKey='moresettings' alwaysOpen>
                            <AccordionItem eventKey='eligibilities'>
                                <AccordionButton>
                                    Category Eligibility Check
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRO label="Eligible for Spend Bonus" value={this.is_eligible_for_spend_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Salary Bonus" value={this.is_eligible_for_salary_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Invest Bonus" value={this.is_eligible_for_invest_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Insure Bonus" value={this.is_eligible_for_insurance_bonus() ? "Yes" : "No"} />
                                    <FormGroupRowRO label="Eligible for Bill Payment Bonus" value={this.is_eligible_for_bill_payment_bonus() ? "Yes" : "No"} />
                                </AccordionBody>
                            </AccordionItem>

                            <AccordionItem eventKey='moresettings'>
                                <AccordionButton>
                                    Additional Settings
                                </AccordionButton>
                                <AccordionBody>
                                    <FormGroupRowRW onChange={this.handleChange.bind(this)} controlId="monthly_bill_txns" label="Monthly Bill Payments" postpend="transactions" placeholder="0" />
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        <FormGroupRowRO postpend="%" label="Effective Interest rate per annum" value={this.calculate_annual_interest_in_percent().toFixed(3)} />
                        {/* <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (30 Days)" value={(this.calculate_annual_interest() / 365 * 30).toFixed(2)} /> */}
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Monthly (31 Days)" value={(this.calculate_annual_interest() / 365 * 31).toFixed(2)} />
                        <FormGroupRowRO prepend="S$" label="Total Interest Earned Yearly (365 Days)" value={this.calculate_annual_interest().toFixed(2)} />
                    </Form>
                </CardBody>
            </Card>
        );
    }
}
export default SCB;